<template>
  <div class="row" v-if="Object.keys(requisition).length !== 0">
    <div class="col-md-12">
      <div class="card">
        <a-spin :spinning="spinning" :delay="delayTime">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <b>Requisition ID: </b> {{ requisition.requisition_number }}<br>
                <b>Requisition Status: </b>
                <a-tag color="#f50">Rejected</a-tag>
                <br>
                <b>Requisition Created at : </b> {{ customDate(requisition.created_at) }}<br>
              </div>
              <div class="col-sm-6">
                <b>Hub: </b> {{ requisition.hub.name }}<br>
                <b>Requisition Rejected at: </b> <span v-if="requisition.requisition_statuses.filter(e => e.status === 'REQUISITION_REJECT').length > 0">{{ customDate(requisition.requisition_statuses.find(e => e.status === 'REQUISITION_REJECT').created_at) }}</span>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form action="">
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th>SL</th>
                <th>Product Name</th>
                <th>Product Unit</th>
                <th>W. Stock</th>
                <th>Hub Stock</th>
                <th>Status</th>
                <th>Requested</th>
                <th>Accepted</th>
                <th>Requested Comment</th>
                <th>Comment</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(requisition_item, index) in requisition.requisition_items" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ requisition_item.product.name }}</td>
                <td>{{ requisition_item.product.unit }}</td>
                <td>{{ requisition_item.product.available_quantity }}</td>
                <td>{{ requisition_item.product.hub_product_stock ? requisition_item.product.hub_product_stock.stock_amount : 0 }}</td>
                <td>
                  <span class="badge badge-warning" v-if="requisition_item.status === 'Pending'">Pending</span>
                  <span class="badge badge-danger" v-if="requisition_item.status === 'Rejected'">Rejected</span>
                  <span class="badge badge-success" v-if="requisition_item.status === 'Accepted'">Accepted</span>
                  <span class="badge badge-warning" v-if="requisition_item.status === 'Partial'">Partial</span>
                </td>
                <td>{{ requisition_item.amount }}</td>
                <td>{{ requisition_item.accepted_amount }}</td>
                <td>{{ requisition_item.req_comment }}</td>
                <td>{{ requisition_item.comment }}</td>
              </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'

export default {
  name: 'admin_view',
  data() {
    return {
      requisition: {},
      loading: false,
      spinning: false,
      acceptRequisitionBtn: false,
      delayTime: 500,
      current: 0,
    }
  },
  mounted() {
    this.getRequisition()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRequisition() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/requisition/reject/view/' + requisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.requisition = data.requisition
          this.current = data.max_item_requisition_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    approve() {
      if (confirm('Do you really want to approve?')) {
        const requisitionId = this.$route.params.requisition_id
        this.acceptRequisitionBtn = true
        apiClient.post('api/admin/hub/requisition/accept/', { requisitionId: requisitionId })
          .then(response => {
            this.acceptRequisitionBtn = false
            if (!response.data.error) {
              this.$notification.success({
                message: response.data.message,
              })
              this.getRequisition()
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          })
          .catch(error => {
            this.acceptRequisitionBtn = false
            console.log(error)
          })
      }
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
